/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "corbel";
  src: url("assets/corbel.ttf") format("truetype");
}

$font-family: "corbel";
$kendo-font-family: "corbel";

$primary: rgb(179, 40, 49);
$kendo-color-primary: $primary;

$secondary: rgb(65, 16, 243);
$kendo-color-secondary: $secondary;

$white: white;
$kendo-color-white: $white;

$font-size: 14px;
$kendo-font-size: $font-size;

$border-radius: 0px;
$theme-type: light;
$adjust-contrast: false;

$hovered-bg: $white;
$kendo-hover-bg: $white;

// $hovered-text: orange;
$selected-bg: $primary;
$kendo-selected-bg: $primary;

$selected-text: $white;
$kendo-selected-text: $white;

$kendo-input-font-size: $font-size !important;
$kendo-input-bg: #ffffff;

$grid-header-padding-y: 5px;
$kendo-grid-header-padding-y: 5px;
$grid-header-font-size: $font-size;
$grid-header-font-family: $font-family;

$grid-cell-padding-y: 5px;
$kendo-grid-cell-padding-y: 5px;

$kendo-button-border-width: 0px;
$kendo-button-border-radius: 0px;
// $kendo-enable-shadows: false;

$dialog-titlebar-bg: $primary;
$kendo-dialog-titlebar-bg: $primary;

$dialog-titlebar-text: #ffffff;
$kendo-dialog-titlebar-text: #ffffff;

$kendo-grid-sorted-bg: $white;

@import "bootstrap5";
@import "@progress/kendo-theme-material/dist/all.scss";

.k-tabstrip-items-wrapper {
  z-index: 0;
}

.k-header input.k-checkbox,
.k-checkbox-cell input.k-checkbox {
  vertical-align: middle;
}

.k-button-solid-primary {
  box-shadow: none;
}

.k-notification-group {
  z-index: 9999;
}

.dx-overlay-wrapper {
  z-index: 10001 !important;
}

[kendowatermarkoverlay],
body>div.ng-star-inserted {
  display: none !important;
}

body>div[style*="display: flex; justify-content: center; align-items: center;"]{display:none!important}
